<template>
  <v-card
    class="rounded-lg problem-card"
    flat
    style="position: relative; max-width: 100%; width: 100%;  box-shadow: 0 .25rem 1rem 0 rgba(48, 53, 69, .08) !important;"
  >
    <!-------------------------------------->
    <!-- PROBLEMA: Enunciat               -->
    <!-------------------------------------->
    <problem-image
      v-if="problem"
      :problem="problem"
      :enunciat="true"
      style="z-index: 2; box-shadow: none !important;"
      class="mt-3"
    ></problem-image>


    <!-------------------------------------->
    <!-- BUTTON: Solució                  -->
    <!-------------------------------------->
    <div style="position: relative; max-width: 100%; width: 100%"
         class="d-flex"
    >

      <v-spacer></v-spacer>

      <div
        class="pa-0 justify-center text-center align-center d-flex"
        :class="$vuetify.breakpoint.xs ? '' : 'col-4'"
      >
        <v-btn
          v-if="!showSolution"
          @click="showSolution = true"
          class="px-12 mb-6 text-none font-weight-bold font-rubik rounded-lg white--text solution-button"
          depressed
          height="46"
          color="primary"
        >
          Mostrar la solució
        </v-btn>
      </div>
      <v-spacer></v-spacer>
    </div>


    <div class="mt-10">

      <v-divider v-if="showSolution"
                 class="mb-5"
      ></v-divider>

      <!-------------------------------------->
      <!-- PROBLEMA: Solució               -->
      <!-------------------------------------->
      <span v-if="showSolution"
            class="font-rubik font-weight-bold pl-10 grey--text text--darken-3"
            style="font-size: 18px"
      >
      Solució
    </span>

      <problem-image
        v-if="showSolution"
        :problem="problem"
        :enunciat="false"
        style="z-index: 2; box-shadow: none !important;"
        class="mt-6"
      ></problem-image>
    </div>
  </v-card>
</template>

<script>
import ProblemImage from "../views/examens/problems/problem-card/ProblemImage.vue";

export default {
  name: "ProblemComponent",
  components: {ProblemImage},
  props: {
    problem: {
      required: true
    }
  },
  data() {
    return {
      showSolution: false
    }
  }
}
</script>

<style scoped>
.solution-button {
  font-size: 16px;
  letter-spacing: normal
}

.solution-button:hover {
  box-shadow: 0px 2px 4px rgba(188, 198, 212, 0.14), 0px 3px 4px rgba(188, 198, 212, 0.12), 0px 1px 5px rgba(188, 198, 212, 0.2) !important;
  background-color: #FFA201;
}

.problem-card {
  border-radius: 0.5rem;
  box-shadow: 0 .25rem 1rem 0 rgba(48, 53, 69, .08);
}

</style>

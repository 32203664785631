<template>
  <div>

    <!--
    <vue-headful
            :title="`${this.assig}: Tots els exericis sobre ${this.$route.params.subject_name}`"
            :description="`Aquí trobaràs tots els problemes de la Selectivitat de ${this.assig} sobre ${this.$route.params.subject_name}.
                                Exàmens selectivitat classifica totes les assignatures per temes.`"

    />
    -->
    <pulseLoader v-if="loading" :loading="loading" style="width: 30px; margin: 40px auto"></pulseLoader>

    <!-------------------------------------->
    <!-- Títol                            -->
    <!-------------------------------------->
    <div v-if="!loading" class="py-0 white" style="position: relative; max-width: 100%">
      <div class="py-6" style="max-width: 1000px; margin: auto">
        <p
          class="font-weight-regular mt-3 ml-6"
          style="font-size: 18px !important;"
        >
          {{ length }} resultats sobre <b>{{ $route.params.search }}</b>.
        </p>
      </div>
    </div>


    <template v-if="!loading && problems">
      <v-row
        no-gutters
        class="pa-0 pt-6"
        style="margin: auto; background-color: rgb(244, 244, 244) !important"
      >
        <!-------------------------------------->
        <!-- Left Column                      -->
        <!-------------------------------------->
        <v-col v-if="!$vuetify.breakpoint.smAndDown" cols="2">

        </v-col>

        <!-------------------------------------->
        <!-- Problemes                        -->
        <!-------------------------------------->
        <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 8" class="pa-0 align-center">
          <!-------------------------------------->
          <!-- Problem Card                     -->
          <!-------------------------------------->

          <problem-component
            v-for="(problem, idx) in problems"
            style="max-width: 800px"
            :key="idx+'-p'"
            :problem="problem"
            class="mb-12 mx-auto"
          ></problem-component>

        </v-col>

        <!-------------------------------------->
        <!-- Right Adsense                    -->
        <!-------------------------------------->
        <v-col v-if="!$vuetify.breakpoint.smAndDown" cols="2">
          <v-card
            v-if="$root.currentUser === null || !$root.currentUser.premium"
            flat color="white" class="sticky mr-4">
            <adsense
              style="display:block; max-width: 210px"
              slotter="8444206542"
            ></adsense>
          </v-card>
        </v-col>


      </v-row>

      <v-row
        no-gutters
        class="pa-0"
        style="margin: auto; background-color: rgb(244, 244, 244) !important;z-index: 10"
      >
        <!-------------------------------------->
        <!-- Problemes Container              -->
        <!-------------------------------------->
        <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 9"
               class="align-center"
               style="z-index: 2"
        >

          <v-row style="margin: auto; height: 100%">

            <v-col
              style="max-width: 800px; margin: auto; height: 100%"
              class="pa-0">
            </v-col>
          </v-row>
        </v-col>
        <!-------------------------------------->
        <!-- Right Adsense                    -->
        <!-------------------------------------->
        <v-col v-if="!$vuetify.breakpoint.smAndDown" cols="2">
          <v-card
            v-if="$root.currentUser === null || !$root.currentUser.premium"
            flat color="white" class="sticky mr-4">
            <adsense
              style="display:block; max-width: 210px"
              slotter="8444206542"
            ></adsense>
          </v-card>
        </v-col>
      </v-row>

      <!-------------------------------------->
      <!-- Pagination                       -->
      <!-------------------------------------->
      <v-pagination
        v-model="page"
        @input="fetchProblems"
        color="blue accent-1"
        circle
        :length="paginationLength"
        class="mb-12"
        :prev-icon="mdiMenuLeft"
        :next-icon="mdiMenuRight"
      ></v-pagination>
    </template>

    <template v-else-if="!problems" class="justify-center">
      <h2 class="pa-12 text-center">
        No s'ha trobat cap problema amb aquestes característiques...
      </h2>
      <v-img max-width="400" style="margin: auto;" contain
             src="https://examenselectivitat.cat:3000/api/images/other/not-found.png"></v-img>
    </template>
  </div>
</template>

<script>
import {mdiMenuLeft} from '/src/assets/mdi.json'
import {mdiMenuRight} from '/src/assets/mdi.json'

import pulseLoader from '../../components/VLoader.vue'
import Adsense from "../../components/anuncis/Adsense";
import ProblemComponent from "../../components/ProblemComponent";

const array = []
export default {
  name: 'Problems',
  components: {
    ProblemComponent,
    Adsense,
    pulseLoader
  },
  metaInfo() {
    return {
      title: `Problemes de la selectivitat sobre ${this.$route.params.search}`,
      titleTemplate: '%s - examenselectivitat',
      meta: [
        {charset: 'utf-8'},
        {
          name: 'description',
          content: `Tots els problemes i solucions sobre ${this.$route.params.search} que han sortit fins ara a les proves d'accés a la universitat.`
        },
        {name: 'viewport', content: 'width=device-width, initial-scale=1'}
      ]
    }
  },
  async mounted() {
    await this.fetchProblems()
  },
  data() {
    return {
      mdiMenuLeft: mdiMenuLeft,
      mdiMenuRight: mdiMenuRight,
      loading: true,
      problems: null,
      subject_name: null,
      page: 1,
      length: null
    }
  },
  computed: {
    senseResultats() {
      return this.buscat && this.resultats.length < 1
    },
    paginationLength() {
      return this.length ? Math.ceil(this.length / 7) : 1
    }
  },
  methods: {
    problemsInit() {
      this.subjectInfo = null
      this.loading = true
      this.problems = null
      this.page = 1
    },
    async goTo(path) {
      await this.$router.push({path: path})
    },
    async getSubjectName() {
      this.loading = true
      const path = `/subjects/name/${this.$route.params.subject_id}`
      await this.axios
        .get(path)
        .then(res => {
          if (res.data.length > 0) {
            this.subject_name = res.data
          }
        })
        .catch((err) => {

        })
      this.loading = false
    },
    async fetchProblems() {
      // Set default values for pagination
      this.loading = true
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0

      // Fetching
      const offset = (this.page - 1) * 7
      const search_keyword = this.$route.params.search
      const subject_id = this.$route.params.subject_id
      const path = `/search/${subject_id}/${search_keyword}/${offset}`

      if (search_keyword.length > 1) {
        await this.axios
          .get(path)
          .then(res => {
            if (res.data.length > 0) {
              this.problems = res.data.problems.map(row => {
                row.keywords = row.keywords.split('|')
                row.keywords_paths = row.keywords_paths.split('|')
                return row
              })
            }
            this.length = res.data.length
            this.getSubjectName()
          })
          .catch((err) => {

            this.loading = false
          })
      } else this.loading = false
    },
    goBack: function () {
      const subject_name = this.$route.params.subject_name
      this.$router.push({name: 'Subject', params: {subject_name}})
    },

    deApostrof(assig) {
      const lletra = assig.charAt(0).toLowerCase()
      return lletra === 'a' || lletra === 'e' || lletra === 'i' || lletra === 'o' || lletra === 'u' || lletra === 'h' ? "d'" + assig : 'de ' + assig
    }
  },
  watch: {
    '$route.params'(val, old) {
      if (old) {
        this.problemsInit()
        this.fetchProblems()
      }
    },
  }
}
</script>

<style scoped>
.notranslate {
  width: 2px !important;
}

.starting-position {
  top: 70px !important;
  transition: top 0.3s;
}

.move-top-scroll {
  top: 0px !important;
  transition: top 0.3s;
}

.starting-position-second {
  top: 470px !important;
  transition: top 0.3s;
}

.move-top-scroll-second {
  top: 400px !important;
  transition: top 0.3s;
}

h4 {
  font-size: 14px !important;
  color: #333333;
  font-weight: 400 !important;
  font-family: Rubik, sans-serif !important;
}

.chip-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
}

.sticky {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 20px;
}

</style>
